#paginasecundaria{
    z-index: 1;
    background: linear-gradient(180deg, #02a388  30%, #55efc4 50%);
    min-height: 100vh; 
    
}

#titulo3{
    color: white;
    font-size: 16px;
    text-align: justify;
}

#titulo1_{
    color: white;
}

#seguir_1{
    width: 150px;
    height: 50px;
    font-size: 18px;
    background: #02a388  100%;
    border-color: white;
    
}

#contenedortexto{
    padding-right: 4%;
    padding-left: 4%;
}
